<template>
	<div class="boma-hero overlay">
		<div class="hero-inner">
			<div class="top-with-navigation">
				<div class="logo">
					<div class="text">
						<p>
							Under the <br class="_rm" />
							aegis of
						</p>
					</div>
					<div class="image img-fluid">
						<img src="@/assets/boma/auweblogo-en.svg" alt="">
					</div>
				</div>
			</div>

			<div class="hero-text">
				<div class="row">
					<div class="col-md-5">
						<div class="image_">
							<img class="img-fluid" src="@/assets/boma/bomalogo.svg" alt="">
						</div>
						<!-- <div class="partners">
							<div class="flex-container">
								<div class="flex-item">
									<img class="img-fluid" src="@/assets/boma/auweblogo-en.svg" alt="">
								</div>
								<div class="flex-item">
									<img class="img-fluid" src="@/assets/boma/auweblogo-en.svg" alt="">
								</div>
								<div class="flex-item">
									<img class="img-fluid" src="@/assets/boma/auweblogo-en.svg" alt="">
								</div>
							</div>
						</div> -->

					</div>
					<div class="col-md-1">

					</div>
					<div class="col-md-5" style="">
						<div class="boma-head">
							<p>
								From the Africa We Want
								to the Africa We Build
							</p>
						</div>

						<div class="date">
							<p>
								Saturday 20th July, 2024 <br />
								10:30am GMT (12:30pm CET)<br>
								Kempinski Hotel, Accra, Ghana
							</p>
						</div>

						<div class="button">

							<a href="https://forms.gle/fUC9HcAmCTWZbrAr5" target="_blank">
								<button class="">
									Register to attend physical event
								</button>
							</a>

						</div>

					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
/* eslint-disable */
export default {};
</script>

<style scoped>
* {
	font-family: "montserrat"
}

.flex-container {
	display: flex;
	justify-content: left;
}

.flex-item {
	margin-right: 10px;
}

.boma-hero {
	width: 100%;
	min-height: 100vh;
	background: #1c1a17;
	background-image: url('@/assets/boma/pattern.svg');
}

.hero-inner {
	padding-right: 5%;
	padding-left: 5%;
}

.hero-inner .top-with-navigation {
	display: flex;
	justify-content: space-between;
}

.hero-inner .top-with-navigation .closebtn {
	display: none;
}

.hero-inner .top-with-navigation .logo {
	width: 114px;
	height: 168px;
	border-bottom-right-radius: 70px;
	border-bottom-left-radius: 70px;
	padding-top: 28px;
	padding-left: 24px;
	padding-right: 24px;
	background-color: #fff;
}

.hero-inner .top-with-navigation .logo .text p {
	margin-bottom: 10px;
	padding: 0px;
	font-family: 'montserrat';
	font-style: normal;
	font-size: 11px;
	text-align: center;
}

.hero-inner .top-with-navigation .overlay-content .navbar {
	padding-top: 50px;
	display: block;
}

.hero-inner .top-with-navigation .overlay-content .navbar a {
	font-family: 'montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
}

.hero-inner .top-with-navigation .overlay-content .navbar a:not(:last-of-type) {
	margin-right: 22px;
}

.hero-inner .top-with-navigation #nav_toggler {
	display: none;
}

.hero-inner .hero-text {
	margin-top: 50px;
}

.hero-inner .hero-text .boma-head {
	margin-bottom: 50px;
}

.hero-inner .hero-text .boma-head p {
	position: relative;
	background: linear-gradient(to right, #83BC3F, #C8A121);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 43px;
	color: white;
	text-align: justify;
	font-family: 'montserrat-bold';

}

.hero-inner .hero-text .date p {
	font-family: 'montserrat';
	font-size: 25px;
	line-height: 40px;
	color: #fff;
	padding: 0px;
	margin: 0px;

}

.hero-inner .hero-text .button {
	margin-top: 40px;
	margin-bottom: 30px
}

.hero-inner .hero-text .button button {
	background: linear-gradient(to right, #83BC3F, #C8A121);
	outline: none;
	border: none;
	font-family: 'montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	text-align: center;
	color: #fff;
	width: 300px;
	height: 50px;
	border-radius: 90px
}

@media screen and (max-width: 768px) {
	._rm {
		display: none;
	}

	.hero-inner {
		padding-right: 10%;
		padding-left: 10%;
		height: 100%;
	}

	.hero-inner .top-with-navigation .logo {
		background-color: #fff;
		width: 80px;
		height: 120px;
		border-bottom-right-radius: 70px;
		border-bottom-left-radius: 70px;
		padding: 10px;
		padding-top: 20px;
	}

	.hero-inner .top-with-navigation #nav_toggler {
		display: block;
		width: 50px;
		height: 50px;
		margin-top: 50px;
		background-color: transparent;
		color: #fff;
		font-size: 2.2em;
		outline: none;
		border: none;
	}

	.hero-inner .top-with-navigation .closebtn {
		display: block;
		font-size: 60px;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		outline: none;
		border: none;
		color: #818181;
		float: right;
	}

	.hero-inner .top-with-navigation .closebtn:hover,
	.hero-inner .top-with-navigation .closebtn:focus {
		color: #f1f1f1;
	}

	.hero-inner .top-with-navigation .overlay-content {
		height: 100%;
		width: 100%;
		position: fixed;
		display: none;
		z-index: 1;
		top: 0;
		left: 0;
		background-color: #000;
		background-color: rgba(0, 0, 0, 0.9);
		overflow-x: hidden;
		transition: 0.5s;
	}

	.hero-inner .top-with-navigation .overlay-content .navbar {
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 0px;
		height: calc(100% - 50px);
	}

	.hero-inner .top-with-navigation .overlay-content .navbar a {
		padding: 8px;
		text-decoration: none;
		font-size: 36px;
		color: #818181;
		display: block;
		transition: 0.3s;
		text-align: center;
	}

	.hero-inner .top-with-navigation .overlay-content .navbar a:hover,
	.hero-inner .top-with-navigation .overlay-content .navbar a:focus {
		color: #f1f1f1;
	}

	.hero-inner .top-with-navigation .overlay-content .navbar a:not(:last-of-type) {
		margin-right: 0px;
		margin-bottom: 25px;
	}

	.hero-inner .hero-text {
		margin-top: 50px;
	}

	.hero-inner .hero-text .boma-head {
		margin-top: 30px;
	}

	.hero-inner .hero-text .boma-head p {
		font-family: 'montserrat-bold';
		font-size: 37px;
		line-height: 1;
	}

	.hero-inner .hero-text .date p {
		font-size: 20px;
		text-align: center;
	}

	.hero-inner .hero-text .button {
		margin-top: 50px;
	}

	.hero-inner .hero-text .button button {
		width: 100%;
	}
}

@media screen and (max-height: 450px) {
	.navbar a {
		font-size: 20px;
	}

	.top-with-navigatio .closebtn {
		font-size: 40px;
	}
}
</style>
