import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { createWebHistory, createRouter } from "vue-router"

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./main.css"

import RegistrationPage from './boma-2024/RegistrationPage.vue'
const routes = [
    {
        path:"/2024/register",
        component:RegistrationPage
    },
    {
        path:"/",
        redirect:"/2024/register"
    },
    {
        path:"/2024/register",
        component:RegistrationPage
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
  });

const app = createApp(App);
app.use(router);
app.mount("#app");